module.exports={
    "dark-mode": "",
    "more": "",
    "search": "",
    "main": "",
    "software": "",
    "download": "",
    "doc": "",
    "documentation": "",
    "wiki": "",
    "wiki-url": "",
    "forum": "",
    "forum-url": "",
    "development": "",
    "development-document": "",
    "development-document-url": "",
    "build-service": "",
    "information": "",
    "news": "",
    "release-notes": "",
    "events": "",
    "planet": "",
    "shop": "",
    "community": "",
    "connect": "",
    "facebook-group": "",
    "mail-lists": "",
    "irc-channels": "",
    "social-media": ""
}
